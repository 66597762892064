import React from "react";

export default function About(props) {
    return (
        <div
            className="container"
            style={{
                backgroundColor: props.mode === "dark" ? "#000" : "white",
                color: props.mode === "dark" ? "white" : "#000",
            }}
        >
            <h1 className="my-3">About Us</h1>
            <div className="accordion" id="accordionExample">
                <div
                    className="accordion-item"
                    style={{
                        color: props.mode === "dark" ? "#00183c" : "#00183c",
                    }}
                >
                    <h4 className="accordion-header" id="headingOne">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                        >
                            <strong> Analyze Your text</strong>
                        </button>
                    </h4>
                    <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            Textutils gives you a way to analyze your text
                            quickly and efficiently. Be it word count, character
                            count or{" "}
                        </div>
                    </div>
                </div>
                <div
                    className="accordion-item"
                    style={{
                        color: props.mode === "dark" ? "#00183c" : "#00183c",
                    }}
                >
                    <h4 className="accordion-header" id="headingTwo">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                        >
                            <strong> Free to use</strong>
                        </button>
                    </h4>
                    <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            TextUtils is a free character counter tool that
                            provides instant character count & word count
                            statistics for a given text. TextUtils reports the
                            number of words and characters. Thus it is suitable
                            for writing text with word/ character limit.
                        </div>
                    </div>
                </div>
                <div
                    className="accordion-item"
                    style={{
                        color: props.mode === "dark" ? "#00183c" : "#00183c",
                    }}
                >
                    <h4 className="accordion-header" id="headingThree">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                        >
                            <strong> Browser Compatible</strong>
                        </button>
                    </h4>
                    <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            This word counter software works in any web browsers
                            such as Chrome, Firefox, Internet Explorer, Safari,
                            Opera. It suits to count characters in facebook,
                            blog, books, excel document, pdf document, essays,
                            etc.{" "}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
